/*
  Fonts
*/
@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Black.otf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Bold-Italic.otf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Light.otf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Light-Italic.otf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Medium.otf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Medium-Italic.otf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Regular.otf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "GT-America";
  src: url("../fonts/GT-America-Regular.otf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Regular-Italic.otf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Thin.otf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Thin-Italic.otf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Ultra-Light.otf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "GT-America-Extended";
  src: url("../fonts/GT-America-Extended-Ultra-Light-Italic.otf") format("truetype");
  font-weight: 100;
  font-style: italic; }

.header__cta, .footer__cta, .technicalSheet__cta {
  background-color: #E5352D;
  color: #FFFFFF;
  padding: 20px 120px;
  text-transform: uppercase;
  display: inline-block;
  transition-property: background-color, color;
  transition-duration: .3s;
  font-size: 13px;
  font-size: 0.8125rem;
  font-family: GT-America-Extended, sans-serif;
  font-weight: bold; }
  .header__cta:hover, .footer__cta:hover, .technicalSheet__cta:hover {
    background-color: #FFFFFF;
    color: #E5352D; }
  @media (max-width: 767.98px) {
    .header__cta, .footer__cta, .technicalSheet__cta {
      padding: 20px 60px; } }
  @media (max-width: 564.98px) {
    .header__cta, .footer__cta, .technicalSheet__cta {
      padding: 20px 30px; } }

.footer__h2, .about__h2, .technicalSheet__h2, .functionality__h2, .references__h2 {
  font-family: GT-America, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #E5352D;
  margin-bottom: 15px; }
  .footer__h2:before, .about__h2:before, .technicalSheet__h2:before, .functionality__h2:before, .references__h2:before {
    content: '';
    display: block;
    background-color: #E5352D;
    height: 1px;
    width: 90px;
    margin-right: 20px; }
  .footer__h2:after, .about__h2:after, .technicalSheet__h2:after, .functionality__h2:after, .references__h2:after {
    content: '.';
    color: #E5352D; }

.functionality {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .functionality {
      padding: 150px 0; } }

.qc-cmp-button {
  background-color: #E5352D !important;
  border-color: #E5352D !important; }

.qc-cmp-button:hover {
  background-color: transparent !important;
  border-color: #E5352D !important;
  color: #E5352D !important; }

.qc-cmp-alt-action,
.qc-cmp-link {
  color: #E5352D !important; }

.qc-cmp-button.qc-cmp-secondary-button:hover {
  border-color: transparent !important;
  background-color: #E5352D !important; }

.qc-cmp-button {
  color: #ffffff !important; }

.qc-cmp-button.qc-cmp-secondary-button {
  color: #1C1C1C !important; }

.qc-cmp-button.qc-cmp-button.qc-cmp-secondary-button:hover {
  color: white !important;
  background-color: black !important; }

.qc-cmp-button.qc-cmp-secondary-button {
  border-color: #eee !important;
  background-color: #eee !important; }

.qc-cmp-ui,
.qc-cmp-ui .qc-cmp-main-messaging,
.qc-cmp-ui .qc-cmp-messaging,
.qc-cmp-ui .qc-cmp-beta-messaging,
.qc-cmp-ui .qc-cmp-title,
.qc-cmp-ui .qc-cmp-sub-title,
.qc-cmp-ui .qc-cmp-purpose-info,
.qc-cmp-ui .qc-cmp-table,
.qc-cmp-ui .qc-cmp-table-header,
.qc-cmp-ui .qc-cmp-vendor-list,
.qc-cmp-ui .qc-cmp-vendor-list-title {
  color: #1C1C1C !important; }

.qc-cmp-ui a,
.qc-cmp-ui .qc-cmp-alt-action,
.qc-cmp-toggle-status {
  color: #E5352D !important; }

.qc-cmp-ui {
  background-color: #ffffff !important; }

.qc-cmp-publisher-purposes-table .qc-cmp-table-header {
  background-color: #ffffff !important; }

.qc-cmp-publisher-purposes-table .qc-cmp-table-row {
  background-color: #ffffff !important; }

.qc-cmp-vendor-list .qc-cmp-vendor-row {
  background-color: #ffffff !important; }

.qc-cmp-vendor-list .qc-cmp-vendor-row-header {
  background-color: #ffffff !important; }

.qc-cmp-table {
  border: 1px solid #000000 !important; }

.qc-cmp-table-row {
  border-top: 1px solid #000000 !important; }

.qc-cmp-table-row:last-child {
  border-bottom: 1px solid #000000 !important; }

.qc-cmp-toggle-status {
  color: #000000 !important; }

.qc-cmp-arrow-down {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='#000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E %3Cpolyline points='2 5 8 11 14 5'/%3E %3C/svg%3E") center no-repeat; }

.qc-cmp-small-toggle.qc-cmp-toggle-on,
.qc-cmp-toggle.qc-cmp-toggle-on {
  background-color: #E5352D !important;
  border-color: #E5352D !important; }

.qc-cmp-toggle-off {
  background-color: black !important; }

.qc-cmp-purpose-description, .qc-cmp-ui .qc-cmp-messaging {
  font-family: GT-America, sans-serif !important; }

.qc-cmp-persistent-link {
  background-color: black !important;
  position: absolute !important;
  right: auto !important;
  font-size: 0 !important; }
  .qc-cmp-persistent-link:before {
    content: 'Website cookie';
    text-transform: uppercase;
    font-size: 10px; }
  .qc-cmp-persistent-link svg {
    display: none; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body {
  font-family: GT-America, sans-serif; }

html {
  min-height: 100%; }

body {
  font-size: 12px;
  font-size: 0.75rem;
  background-color: #FFFFFF;
  color: #000000;
  margin: 0;
  line-height: 1;
  position: relative; }
  body .header {
    left: 0;
    z-index: 100; }
    body .header, body .header--initial {
      position: absolute;
      top: 0; }
    body .header--transition {
      position: fixed;
      top: -130px !important;
      transition: top .5s; }
    body .header--transitionDown {
      position: fixed;
      top: -130px !important; }
    body .header--sticky {
      position: fixed;
      top: 0;
      transition: top .5s; }

::selection {
  color: #FFFFFF;
  background: #000000; }

a {
  color: #000000;
  font-size: 12px;
  font-size: 0.75rem;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }
  a:visited {
    text-decoration: none; }

h1, h2, h3 {
  margin: 0;
  font-family: GT-America-Extended, sans-serif; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

*::-webkit-scrollbar {
  width: 10px; }

*::-webkit-scrollbar-track {
  background-color: #fafafa; }

*::-webkit-scrollbar-thumb {
  background-color: #666666; }

p {
  font-family: GT-America-Extended, sans-serif;
  margin: 0; }

button {
  background: none;
  cursor: pointer;
  box-sizing: content-box;
  border: none; }
  button:focus {
    outline: none; }

.container {
  display: flex;
  margin-left: 30px;
  margin-right: 30px; }
  @media (min-width: 768px) {
    .container {
      margin-left: 50px;
      margin-right: 50px; } }
  @media (min-width: 1024px) {
    .container {
      margin-left: 70px;
      margin-right: 70px; } }
  @media (min-width: 1140px) {
    .container {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto; } }

.container-fluid {
  width: 100%;
  padding: 0 30px; }

.burger__button:before, .burger__button:after, .burger__line {
  display: block;
  width: 16px;
  height: 2px;
  background-color: #000000; }

.burger__button:before, .burger__button:after {
  position: absolute;
  content: ''; }

.header__burger {
  display: none;
  align-self: flex-start;
  align-items: center;
  margin: 0;
  height: 90px; }

.header {
  width: 100%;
  z-index: 100;
  padding-top: 65px;
  position: relative;
  box-sizing: content-box; }
  @media (max-width: 767.98px) {
    .header {
      padding-top: 30px; } }
  .header .container {
    justify-content: space-between; }
  .header .navbar-brand {
    display: flex;
    height: 100%;
    align-items: center; }
  .header__logo {
    height: 40px; }
    @media (min-width: 768px) {
      .header__logo {
        height: 78px;
        transition: height .3s; } }
  .header__cta {
    padding: 15px 20px;
    text-transform: none; }
  .header--transition {
    background-color: #1C1C1C;
    padding: 20px 0; }
    .header--transitionDown {
      padding: 20px 0; }
    .header--transition .header__logo {
      height: 50px; }
  .header--sticky {
    background-color: #1C1C1C;
    padding: 20px 0; }
    .header--sticky .header__logo {
      height: 50px; }
  @media (min-width: 800px) {
    .header__burger {
      display: none; } }
  @media (max-width: 1023.98px) {
    .header__burger {
      display: flex; } }

.nav {
  display: none; }
  @media (min-width: 1024px) {
    .nav {
      display: block; } }
  .nav__listItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%; }
  .nav__link {
    color: white;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: GT-America-Extended, sans-serif;
    opacity: 1;
    transition-property: opacity;
    transition-duration: .3s; }
    .nav__link:hover {
      opacity: .7; }
    @media (max-width: 1023.98px) {
      .nav__link {
        font-weight: bold; } }
  .nav__item {
    margin: 0 25px; }
    .nav__item:first-child {
      margin-left: 0; }
    .nav__item:last-child {
      margin-right: 0; }

.languages__item {
  display: none;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center; }
  .languages__item--active {
    display: flex; }
    .languages__item--active:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-bottom: 1px solid white;
      border-left: 1px solid white;
      transform: rotateZ(-45deg) translateY(-4px);
      margin-left: 8px; }

.languages__link {
  color: white;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  text-transform: uppercase; }

.listResponsive {
  display: none; }
  .listResponsive__item {
    width: 100%;
    margin-bottom: 10px; }
    .listResponsive__item--facebook a {
      display: inline-block;
      margin-bottom: 40px; }
  .listResponsive__link {
    color: white;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 400; }
    @media (max-width: 1023.98px) {
      .listResponsive__link {
        font-weight: bold; } }

.burger__button:before, .burger__button:after, .burger__line {
  display: block;
  background-color: #FFFFFF;
  height: 2px; }

.burger__button:before, .burger__button:after {
  content: '';
  position: absolute; }

.burger {
  display: none;
  align-self: flex-start;
  align-items: center;
  margin: 0;
  height: 90px;
  width: 28px;
  position: absolute;
  right: 70px;
  top: 65px;
  z-index: 51; }
  @media (max-width: 767.98px) {
    .burger {
      right: 30px;
      top: 30px;
      height: 40px; } }
  @media (max-width: 1023.98px) {
    .burger {
      display: flex; } }
  .burger__button {
    position: relative;
    width: 22px;
    height: 22px;
    padding: 0; }
    .burger__button:before {
      top: 2px;
      width: 22px;
      transition-property: top, transform;
      transition-duration: 0.2s;
      transition-delay: 0.3s, 0s; }
    .burger__button:after {
      bottom: 2px;
      width: 11px;
      transition-property: bottom, transform, width;
      transition-duration: 0.2s;
      transition-delay: 0.3s, 0s, 0s; }
    .burger__button:hover:after {
      width: 22px; }
    .burger__button:hover .burger__line {
      width: 22px; }
  .burger__line {
    width: 16.5px;
    opacity: 1;
    transition-property: width, opacity;
    transition-duration: 0.3s; }

.header--sticky .burger, .header--transition .burger {
  top: 0; }
  @media (max-width: 767.98px) {
    .header--sticky .burger, .header--transition .burger {
      top: 30px; } }

.header--initial .burger {
  height: 78px; }
  @media (max-width: 767.98px) {
    .header--initial .burger {
      height: 40px; } }

.header--responsive .burger__button {
  position: relative;
  flex-flow: initial; }
  .header--responsive .burger__button:before {
    width: 22px;
    transform: rotate(-45deg);
    top: 10px;
    transition-delay: 0s, 0.3s; }
  .header--responsive .burger__button:after {
    width: 22px;
    transform: rotate(45deg);
    bottom: 10px;
    transition-delay: 0s, 0.3s; }
  .header--responsive .burger__button .burger__line {
    opacity: 0;
    transition: opacity 0s; }

@media (max-width: 1023.98px) {
  .body--responsive {
    overflow: hidden; }
  .header--responsive .nav {
    transform: translateX(-100%);
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-delay: .3s; }
    .header--responsive .nav__item {
      transform: translateY(0px);
      opacity: 1;
      transition-property: transform, opacity;
      transition-duration: .3s;
      transition-timing-function: ease-in; }
      .header--responsive .nav__item:nth-child(0) {
        transition-delay: 700ms; }
      .header--responsive .nav__item:nth-child(1) {
        transition-delay: 720ms; }
      .header--responsive .nav__item:nth-child(2) {
        transition-delay: 740ms; }
      .header--responsive .nav__item:nth-child(3) {
        transition-delay: 760ms; }
      .header--responsive .nav__item:nth-child(4) {
        transition-delay: 780ms; }
      .header--responsive .nav__item:nth-child(5) {
        transition-delay: 800ms; }
  .header--responsive .listResponsive {
    transform: translateY(0px);
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    transition-delay: 820ms; }
  .header__languages {
    margin-top: 40px;
    height: 60px; }
    .header__languages .languages__item {
      height: auto; }
  .nav {
    display: flex;
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #1C1C1C;
    padding: 0 50px;
    transform: translateX(0%);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-delay: .5s;
    z-index: 50;
    box-sizing: border-box;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 90px; } }
  @media (max-width: 1023.98px) and (max-width: 767.98px) {
    .nav {
      padding-top: 40px; } }

@media (max-width: 1023.98px) {
    .nav__listItem {
      display: flex;
      list-style: none;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      height: 80%;
      width: 100%; }
    .nav__item {
      padding: 0;
      margin: 0 0 4% 0;
      display: flex;
      align-items: center;
      transform: translateY(50px);
      opacity: 0;
      box-sizing: border-box;
      transition-property: transform, opacity;
      transition-duration: .3s;
      transition-timing-function: ease-in; } }
    @media (max-width: 1023.98px) and (max-width: 767.98px) {
      .nav__item {
        margin-bottom: 10%; } }

@media (max-width: 1023.98px) {
      .nav__item:nth-child(0) {
        transition-delay: 0ms;
        order: 1; }
      .nav__item:nth-child(1) {
        transition-delay: 20ms;
        order: 2; }
      .nav__item:nth-child(2) {
        transition-delay: 40ms;
        order: 3; }
      .nav__item:nth-child(3) {
        transition-delay: 60ms;
        order: 4; }
      .nav__item:nth-child(4) {
        transition-delay: 80ms;
        order: 5; }
      .nav__item:nth-child(5) {
        transition-delay: 100ms;
        order: 6; }
    .nav__link {
      display: flex;
      align-items: center;
      font-size: 6vw;
      text-decoration: none;
      color: white;
      width: fit-content; }
      .nav__link--active:after {
        display: none; }
  .listResponsive {
    display: flex;
    flex-flow: column;
    transform: translateY(50px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-in;
    transition-delay: 120ms; } }

.footer {
  background-color: #1C1C1C;
  padding: 150px 0 50px 0;
  color: #FFFFFF;
  background-image: url("../img/logofooter.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top; }
  @media (max-width: 767.98px) {
    .footer {
      background: #1C1C1C; } }
  .footer__container {
    display: flex;
    flex-flow: column; }
  .footer__titleInformations {
    display: flex;
    justify-content: space-between;
    flex-flow: column; }
    @media (min-width: 1024px) {
      .footer__titleInformations {
        flex-flow: row; } }
  .footer__importantTitle {
    width: 100%; }
    @media (min-width: 1024px) {
      .footer__importantTitle {
        width: 65%; } }
    @media (min-width: 1140px) {
      .footer__importantTitle {
        width: 60%; } }
  .footer__informations {
    width: 100%;
    display: flex;
    justify-content: center; }
    @media (min-width: 1024px) {
      .footer__informations {
        width: 35%; } }
    @media (min-width: 1140px) {
      .footer__informations {
        width: 40%; } }
  .footer__paragraph {
    font-size: 44px;
    font-size: 2.75rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif;
    padding-left: 110px;
    max-width: 560px;
    margin: 0 0 70px; }
    @media (max-width: 767.98px) {
      .footer__paragraph {
        margin-bottom: 45px; } }
    @media (max-width: 564.98px) {
      .footer__paragraph {
        padding: 0;
        font-size: 30px;
        font-size: 1.875rem; } }
  .footer__containerBtn {
    margin-left: 110px;
    margin-bottom: 150px; }
    @media (max-width: 767.98px) {
      .footer__containerBtn {
        margin-bottom: 45px; } }
    @media (max-width: 564.98px) {
      .footer__containerBtn {
        margin-left: 0;
        display: flex;
        justify-content: center; } }

@media (max-width: 1023.98px) {
  .informations__item {
    display: flex;
    flex-flow: column;
    align-items: center; } }

.informations__titleContact {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #E5352D;
  margin-bottom: 15px; }

.informations__linkContact {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 45px;
  display: inline-block;
  opacity: 1;
  transition: opacity .3s;
  max-width: 200px; }
  .informations__linkContact--address {
    line-height: 23px; }
    @media (max-width: 1023.98px) {
      .informations__linkContact--address {
        text-align: center; } }
  .informations__linkContact:hover {
    opacity: .8; }
  .informations__linkContact--facebook {
    display: inline-block;
    width: 40px;
    height: 40px; }
    .informations__linkContact--facebook path {
      fill: white;
      transition: fill .3s; }
    .informations__linkContact--facebook:hover path {
      fill: #E5352D; }

.privacyCGU {
  border-top: 1px solid #414141;
  padding-top: 20px;
  display: flex; }
  @media (min-width: 1024px) {
    .privacyCGU {
      margin: 0 70px;
      padding-top: 10px; } }
  @media (max-width: 767.98px) {
    .privacyCGU {
      margin: 0 30px;
      flex-flow: row wrap; } }
  @media (max-width: 564.98px) {
    .privacyCGU {
      margin: 0 30px; } }
  .privacyCGU__item {
    width: 50%; }
    @media (max-width: 767.98px) {
      .privacyCGU__item {
        width: 100%;
        text-align: center; } }
  .privacyCGU__alignRight {
    text-align: right;
    width: 50%; }
    @media (max-width: 767.98px) {
      .privacyCGU__alignRight {
        text-align: center;
        margin-top: 20px;
        width: 100%; } }
  .privacyCGU__linkBottom {
    font-size: 10px;
    font-size: 0.625rem;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    transition: opacity .3s; }
    .privacyCGU__linkBottom:hover {
      opacity: .8; }
    .privacyCGU__linkBottom--kernit {
      text-decoration: underline; }

.hero {
  height: 100vh;
  background-color: black;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  min-height: 800px;
  position: relative; }
  @media (max-width: 767.98px) {
    .hero {
      height: auto;
      min-height: 100vh;
      align-items: flex-start;
      padding-top: 200px; } }
  @media (max-width: 1023.98px) {
    .hero {
      background-image: url("../img/header-banner.png");
      background-repeat: no-repeat;
      background-position: center center; } }
  .hero__container {
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    flex-flow: column;
    align-content: flex-end;
    position: relative;
    z-index: 2; }
    @media (min-width: 1140px) {
      .hero__container {
        padding-bottom: 80px; } }
  .hero__videoBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    @media (max-width: 1023.98px) {
      .hero__videoBox {
        display: none; } }
  .hero__video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .3; }
  .hero__h1 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: bold;
    max-width: 608px;
    color: #FFFFFF;
    margin-bottom: 30px; }
    @media (min-width: 565px) {
      .hero__h1 {
        font-size: 42px;
        font-size: 2.625rem; } }
    @media (min-width: 1024px) {
      .hero__h1 {
        max-width: 768px;
        font-size: 51px;
        font-size: 3.1875rem; } }
    @media (min-width: 1140px) {
      .hero__h1 {
        max-width: 860px;
        font-size: 60px;
        font-size: 3.75rem; } }
  .hero__subtitle {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: normal;
    color: #FFFFFF;
    margin-bottom: 60px;
    padding-right: 100px;
    line-height: 20px; }
    @media (max-width: 767.98px) {
      .hero__subtitle {
        padding: 0; } }
    @media (min-width: 565px) {
      .hero__subtitle {
        font-size: 20px;
        font-size: 1.25rem;
        margin-bottom: 60px; } }
  .hero__listItem {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
  .hero__item {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
    .hero__item:first-child {
      padding-left: 0; }
    @media (max-width: 1139.98px) {
      .hero__item:nth-child(odd) {
        padding-left: 0; } }
    .hero__item:last-child {
      padding: 0; }
    @media (min-width: 565px) {
      .hero__item {
        width: 50%;
        padding: 10px 10px;
        box-sizing: border-box; } }
    @media (min-width: 1024px) {
      .hero__item {
        width: 25%; } }
    @media (min-width: 1140px) {
      .hero__item {
        padding: 0 10px;
        width: 30%; } }
  .hero__title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif;
    text-transform: uppercase;
    color: #E5352D;
    margin-bottom: 10px; }
  .hero__paragraph {
    font-size: 14px;
    font-size: 0.875rem;
    font-family: GT-America, sans-serif;
    color: #FFFFFF;
    line-height: 20px;
    margin-top: 14px; }
  .hero__play {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 1024px) {
      .hero__play {
        width: 60px; } }

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(229, 53, 45, 0.5); }
  40% {
    box-shadow: 0 0 0 10px rgba(229, 53, 45, 0.3); }
  80% {
    box-shadow: 0 0 0 10px rgba(229, 53, 45, 0); }
  100% {
    box-shadow: 0 0 0 rgba(229, 53, 45, 0); } }

@keyframes animateWhite {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  40% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3); }
  80% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0); } }

.play {
  justify-content: left; }
  .play__button {
    display: inline-block;
    cursor: pointer;
    animation: animate 2s linear infinite;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 5px; }
    .play__button path {
      fill: #E5352D;
      transition: fill .3s; }
    .play__button:hover {
      animation: animateWhite 2s linear infinite; }
      .play__button:hover path {
        fill: white; }

.about {
  padding: 150px 0;
  background: linear-gradient(90deg, white calc((100% / 3) * 2), #F5F5F5 calc(100% / 3));
  position: relative; }
  @media (max-width: 767.98px) {
    .about {
      padding: 75px 0; } }
  @media (max-width: 1023.98px) {
    .about {
      background: none; } }
  .about__blockDeco {
    position: absolute;
    display: block;
    background-color: #E5352D; }
    @media (max-width: 1023.98px) {
      .about__blockDeco {
        display: none; } }
    .about__blockDeco--up {
      top: 0;
      left: 0;
      height: 41px;
      width: calc(100% / 3); }
    .about__blockDeco--down {
      width: 57px;
      height: 19px;
      right: 80px;
      bottom: 40px; }
    .about__blockDeco--between {
      width: 237px;
      height: 40px;
      right: 270px;
      bottom: -20px;
      z-index: 5; }
  .about__container {
    display: flex;
    flex-flow: row wrap; }
  .about__h2 {
    position: relative; }
  .about__subtitle {
    max-width: 310px;
    margin-bottom: 30px;
    font-size: 33px;
    font-size: 2.0625rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif; }
    @media (min-width: 565px) {
      .about__subtitle {
        font-size: 36px;
        font-size: 2.25rem; } }
    @media (min-width: 768px) {
      .about__subtitle {
        margin-left: 110px;
        font-size: 44px;
        font-size: 2.75rem; } }
  .about__h3 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif;
    max-width: 360px;
    color: #E5352D;
    margin-bottom: 20px;
    position: relative;
    padding: 38px 38px 0 38px; }
    @media (max-width: 1023.98px) {
      .about__h3 {
        max-width: none; } }
    @media (max-width: 767.98px) {
      .about__h3 {
        padding: 38px 0 0 0; } }
    @media (max-width: 1023.98px) {
      .about__h3 {
        font-size: 24px;
        font-size: 1.5rem; } }
    .about__h3:before {
      content: '';
      display: flex;
      width: 55px;
      height: 22px;
      align-items: center;
      justify-content: flex-end;
      color: white;
      background-color: #E5352D;
      position: absolute;
      top: 0;
      left: 0; }
  .about__paragraph {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 500;
    font-family: GT-America, sans-serif;
    max-width: 360px;
    padding: 0 38px 30px 38px;
    line-height: 23px;
    margin: 0; }
    @media (max-width: 1023.98px) {
      .about__paragraph {
        max-width: none; } }
    @media (max-width: 767.98px) {
      .about__paragraph {
        padding: 0 0 30px 0; } }

.whyBlocks {
  display: flex;
  flex-flow: row wrap; }
  .whyBlocks:nth-of-type(2) h3:before {
    content: "01.";
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    padding-right: 9px; }
  .whyBlocks:nth-of-type(3) h3:before {
    content: "02.";
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    padding-right: 9px; }
  .whyBlocks:nth-of-type(4) h3:before {
    content: "03.";
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    padding-right: 9px; }
  @media (max-width: 767.98px) {
    .whyBlocks:last-child .whyBlocks__containerImage {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .whyBlocks {
      flex-flow: column; } }
  @media (max-width: 767.98px) {
    .whyBlocks__containerImage {
      margin-bottom: 50px; } }
  .whyBlocks__containerImage, .whyBlocks__containerText {
    padding: 0;
    width: 50%; }
    @media (max-width: 767.98px) {
      .whyBlocks__containerImage, .whyBlocks__containerText {
        width: 100%; } }
  @media (min-width: 768px) {
    .whyBlocks__containerText {
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center; } }
  @media (min-width: 768px) {
    .whyBlocks:nth-child(odd) .whyBlocks__containerImage {
      order: 1; }
    .whyBlocks:nth-child(odd) .whyBlocks__containerText {
      order: 2; } }
  .whyBlocks__containerImage {
    justify-content: flex-end; }
  .whyBlocks__image {
    object-fit: cover;
    width: 100%; }
  .whyBlocks__img {
    width: 100%; }

.technicalSheet {
  padding: 150px 0;
  display: flex;
  align-items: center;
  background-color: #1C1C1C;
  color: white;
  position: relative; }
  @media (max-width: 767.98px) {
    .technicalSheet {
      padding: 75px 0; } }
  .technicalSheet__blockDeco {
    position: absolute;
    display: block;
    background-color: #E5352D; }
    @media (max-width: 1023.98px) {
      .technicalSheet__blockDeco {
        display: none; } }
    .technicalSheet__blockDeco--down {
      width: 57px;
      height: 19px;
      left: 227px;
      bottom: 30px; }
    .technicalSheet__blockDeco--between {
      width: 193px;
      height: 40px;
      left: 0;
      bottom: -31px;
      z-index: 5; }
    .technicalSheet__blockDeco--middle {
      width: 137px;
      height: 29px;
      bottom: 190px;
      right: 70px; }
  .technicalSheet__container {
    flex-flow: column;
    width: 100%;
    align-items: center; }
    @media (min-width: 1024px) {
      .technicalSheet__container {
        flex-flow: row; } }
  .technicalSheet__machine {
    width: 80%;
    margin: auto; }
    @media (min-width: 768px) {
      .technicalSheet__machine {
        width: 50%; } }
    @media (min-width: 1024px) {
      .technicalSheet__machine {
        order: 1;
        margin: 0; } }
  .technicalSheet__informations {
    width: 100%;
    margin-bottom: 50px; }
    @media (min-width: 1024px) {
      .technicalSheet__informations {
        order: 2;
        width: 50%;
        margin-bottom: 0; } }
  .technicalSheet__image {
    width: 100%; }
  .technicalSheet__subtitle {
    margin-bottom: 30px;
    font-size: 33px;
    font-size: 2.0625rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif; }
    @media (min-width: 565px) {
      .technicalSheet__subtitle {
        font-size: 36px;
        font-size: 2.25rem; } }
    @media (min-width: 768px) {
      .technicalSheet__subtitle {
        margin-left: 110px;
        font-size: 44px;
        font-size: 2.75rem; } }
  .technicalSheet__listItem {
    margin-bottom: 60px;
    padding-left: 0; }
    @media (min-width: 768px) {
      .technicalSheet__listItem {
        padding-left: 110px; } }
  .technicalSheet__item {
    font-size: 16px;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .technicalSheet__item {
        font-size: 22px;
        font-size: 1.375rem;
        margin-bottom: 30px; } }
    .technicalSheet__item:last-child {
      margin-bottom: 0; }
  .technicalSheet__containerBtn {
    margin-left: 0; }
    @media (min-width: 768px) {
      .technicalSheet__containerBtn {
        margin-left: 110px; } }
  .technicalSheet__cta {
    padding: 20px 30px; }

.functionality {
  background-color: #F5F5F5;
  position: relative;
  min-height: 100vh;
  display: flex; }
  @media (max-width: 767.98px) {
    .functionality {
      padding: 75px 0; } }
  @media (min-width: 1024px) {
    .functionality {
      padding: 0; } }
  .functionality__blockDeco {
    position: absolute;
    display: block;
    background-color: #E5352D; }
    @media (max-width: 1023.98px) {
      .functionality__blockDeco {
        display: none; } }
    .functionality__blockDeco--up {
      width: 300px;
      height: 90px;
      left: 0;
      bottom: -45px;
      z-index: 5; }
  @media (min-width: 1024px) {
    .functionality .container-fluid {
      padding: 0;
      display: flex; } }
  @media (min-width: 1024px) {
    .functionality__containerItem {
      padding: 150px 0; } }
  .functionality__subtitle {
    margin-bottom: 30px;
    font-size: 33px;
    font-size: 2.0625rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif; }
    @media (min-width: 565px) {
      .functionality__subtitle {
        font-size: 36px;
        font-size: 2.25rem; } }
    @media (min-width: 768px) {
      .functionality__subtitle {
        margin-left: 110px;
        font-size: 44px;
        font-size: 2.75rem; } }
  .functionality__h3 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .functionality__h3 {
        font-size: 22px;
        font-size: 1.375rem; } }
    .functionality__h3:before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("../img/bullet-item.png");
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: left center;
      display: block;
      padding-right: 10px;
      box-sizing: content-box; }
      @media (min-width: 768px) {
        .functionality__h3:before {
          padding-right: 15px; } }
  .functionality__paragraph {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 500;
    font-family: GT-America, sans-serif;
    line-height: 23px;
    margin: 0; }
    @media (min-width: 768px) {
      .functionality__paragraph {
        margin-left: 39px; } }
  @media (min-width: 768px) {
    .functionality__listItem {
      margin-left: 47px; } }
  .functionality__item {
    padding: 20px;
    cursor: pointer; }
    @media (min-width: 768px) {
      .functionality__item {
        padding: 30px 80px 30px 25px; } }
    .functionality__item--active {
      background-color: white; }
  .functionality__containerItem {
    margin-bottom: 50px; }
    @media (min-width: 1024px) {
      .functionality__containerItem {
        margin-bottom: 0;
        width: 50%; } }
  @media (min-width: 1024px) {
    .functionality__containerRight {
      max-width: 645px;
      margin-left: auto; } }

.galleryFunctional, .galleryReference {
  height: 50vh;
  width: 100%;
  overflow: hidden;
  position: relative; }
  @media (min-width: 1024px) {
    .galleryFunctional, .galleryReference {
      min-height: 100vh;
      height: 100%;
      width: 50%;
      padding: 0;
      overflow: hidden; } }
  .galleryFunctional__image, .galleryReference__image {
    object-fit: cover;
    height: 100%; }
    @media (min-width: 565px) {
      .galleryFunctional__image, .galleryReference__image {
        height: 100%;
        width: auto; } }
    @media (min-width: 1024px) {
      .galleryFunctional__image, .galleryReference__image {
        min-height: 100vh;
        width: 100%; } }
  .galleryFunctional__listItem {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex; }
  .galleryFunctional__item {
    top: 0;
    left: 0;
    display: block;
    padding-right: 10px; }
    .galleryFunctional__item:last-child {
      padding: 0; }

.references {
  min-height: 100vh;
  padding: 150px 0;
  box-sizing: border-box; }
  @media (max-width: 767.98px) {
    .references {
      padding: 75px 0; } }
  .references__containerInformation {
    margin-bottom: 100px; }
  .references__informations {
    display: flex;
    width: 100%; }
    @media (max-width: 1023.98px) {
      .references__informations {
        flex-flow: column; } }
  @media (max-width: 1139.98px) {
    .references__h2:before {
      display: none; } }
  .references__subtitle {
    max-width: 440px;
    margin-bottom: 30px;
    font-size: 33px;
    font-size: 2.0625rem;
    font-weight: bold;
    font-family: GT-America-Extended, sans-serif;
    margin-left: 110px;
    font-size: 36px;
    font-size: 2.25rem; }
    @media (max-width: 1139.98px) {
      .references__subtitle {
        margin-left: 0; } }
    @media (min-width: 768px) {
      .references__subtitle {
        font-size: 44px;
        font-size: 2.75rem; } }
  .references__listItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 42px; }
    @media (max-width: 767.98px) {
      .references__listItem {
        justify-content: center;
        flex-flow: row wrap; } }
  .references__item {
    margin-right: 50px; }
    @media (max-width: 767.98px) {
      .references__item {
        margin-bottom: 20px; } }
    .references__item:last-child {
      margin-right: 0; }
  .references__standard {
    width: 76px; }
  .references__kaseupen {
    width: 86px; }
  .references__osmoz {
    width: 265px; }

.galleryReference {
  height: 515px;
  min-height: 515px;
  width: 100%;
  position: relative; }
  .galleryReference__listItem {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex; }
  .galleryReference__item {
    height: 100%;
    position: relative; }
  .galleryReference__image {
    min-height: auto;
    width: 100%; }
  .galleryReference__description {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px 14px;
    background-color: #E5352D; }
  .galleryReference__title {
    color: white;
    font-size: 22px;
    font-size: 1.375rem;
    font-family: GT-America-Extended, sans-serif;
    font-weight: bold; }
  .galleryReference__typeMachine {
    font-size: 16px;
    font-size: 1rem;
    font-family: GT-America, sans-serif;
    display: block; }
  .galleryReference .tns-outer, .galleryReference .tns-ovh, .galleryReference .tns-inner {
    height: 100%; }
  .galleryReference .tns-outer {
    position: relative; }

.tns-nav, .tns-liveregion {
  display: none; }

.tns-controls {
  width: 100%;
  height: 100%;
  position: absolute; }
  .tns-controls button {
    position: absolute;
    display: flex;
    top: 0;
    width: 100px;
    height: 100%;
    cursor: pointer;
    font-size: 0;
    align-items: center;
    z-index: 50;
    text-indent: -5000px; }
    .tns-controls button:hover:after {
      opacity: .8; }
    .tns-controls button:after {
      background-image: url("/img/arrow.svg");
      background-repeat: no-repeat;
      opacity: .6;
      background-color: #1C1C1C;
      background-position: center;
      width: 64px;
      height: 64px;
      content: '';
      display: block;
      border-radius: 50%; }
    .tns-controls button[data-controls="prev"] {
      left: 0;
      justify-content: flex-end; }
      .tns-controls button[data-controls="prev"]:after {
        transform: rotate(180deg); }
    .tns-controls button[data-controls="next"] {
      right: 0; }

.popup {
  display: block;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(28, 28, 28, 0.8);
  z-index: -1;
  transition-property: opacity;
  transition-duration: .3s; }
  .popup--active {
    opacity: 1; }
    .popup--active .popup__stage {
      width: calc(90vw - 10px);
      height: calc((90vw - 10px) / 1.7777); }
  .popup__container {
    width: 100%;
    height: 100vh; }
  .popup__stage {
    width: 50%;
    height: 50%;
    box-sizing: border-box;
    position: absolute;
    z-index: 50;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition-property: width, height;
    transition-duration: .5s; }
  .popup__video {
    width: 100%;
    height: 100%; }
  .popup__controls {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 49; }
  .popup__close {
    color: white;
    display: block;
    width: 44px;
    height: 44px;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: .8; }
    .popup__close path {
      fill: white; }
    .popup__close:hover {
      opacity: 1; }
